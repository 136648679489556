import { Middleware } from '@nuxt/types';

import {
  getRedirectURI,
  getCancelLoginURI,
  validSessionCreated
} from '@/lib/login';

const rotweillerMigrationMiddleware: Middleware = async ({
  app,
  query,
  store,
  redirect
}) => {
  const rottweilerSessionId = query.rid as string;
  const rottweilerFingerprint = query.rf as string;
  if (!rottweilerSessionId || !rottweilerFingerprint) return;

  const redirectURI: string = getRedirectURI(query) ?? '';
  const cancelURI = getCancelLoginURI(query, redirectURI);

  const response = await store.dispatch('sso/migrate', {
    redirectURI,
    rottweilerSessionId,
    rottweilerFingerprint
  });

  if (response.error) return redirect(cancelURI);
  const { user, pin_code, exchange_token, session } = response;

  if (exchange_token && session) {
    store.commit('sso/UPDATE_SUCCESSFUL_MIGRATION', true);
    validSessionCreated({
      redirect,
      redirectURI,
      $nuxtCookies: app.$nuxtCookies,
      session: { session, exchange_token }
    });
  } else if (pin_code && user) {
    store.commit('sso/SET_PIN_REFERENCE', {
      pin: pin_code,
      email: user.email,
      needsValidation: true
    });
  }
};

export default rotweillerMigrationMiddleware;
