import { SSOState } from './state';

import { MutationTree } from 'vuex';

const mutations: MutationTree<SSOState> = {
  SET_AUTHENTICATORS: (state, authenticators) => {
    state.authenticators = authenticators;
  },
  SHOW_SNACKBAR: (state, snackbar) => {
    state.snackbar.show = true;
    state.snackbar = { ...state.snackbar, ...snackbar };
  },
  HIDDEN_SNACKBAR: (state) => {
    state.snackbar.show = false;
  },
  SET_FINGERPRINT: (state, fingerprint) => {
    state.fingerprint = fingerprint;
  },
  SET_EMAIL: (state, email) => {
    state.email = email;
  },
  SET_PIN_REFERENCE: (
    state,
    { pin = {}, email = '', needsValidation = false }
  ) => {
    const { reference_id, resend_blocked, expires_at, resend_blocked_until } =
      pin;

    state.email = email || state.email;

    state.pin.id = reference_id;
    state.pin.blocked = resend_blocked;
    state.pin.expiration = expires_at;
    state.pin.resend = resend_blocked_until;
    state.migration.needsValidation = needsValidation;
  },
  SET_REDIRECTS_URIS: (state, uris) => {
    const { redirectURI, cancelURI } = uris;

    state.cancelURI = cancelURI;
    state.redirectURI = redirectURI;
  },
  UPDATE_PIN_BLOCKED: (state, blocked) => {
    state.pin.blocked = blocked;
  },
  UPDATE_SUCCESSFUL_MIGRATION: (state, success) => {
    state.migration.successful = success;
  },
  SET_ONE_TAP_ERROR: (state, hasError) => {
    state.social.oneTapError = hasError;
  }
};

export default mutations;
