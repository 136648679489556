import { WhitelabelState } from './state';

import { ActionTree } from 'vuex';

const actions: ActionTree<WhitelabelState, WhitelabelState> = {
  async getWhitelabelStore({ commit }, { subdomain, labrador }) {
    try {
      const { data: whitelabel } = await this.$axios.get(
        `${labrador}/v1/webstore`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Whitelabel-Subdomain': subdomain || ''
          }
        }
      );

      commit('setStore', whitelabel);
    } catch (error) {}
  }
};

export default actions;
