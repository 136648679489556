import { Middleware } from '@nuxt/types';
// @ts-ignore
import { encode } from '@petlove/frontend-utilities-utils';
import { Route } from 'vue-router';

import {
  getCancelLoginURI,
  getSessionCookies,
  removeSessionCookies
} from '@/lib/login';

const getAuthorizedRouteCallback = (route: Route) => {
  return {
    name: 'index',
    query: { icb: encode(route.fullPath), ...route.query }
  };
};

const loggedMiddleware: Middleware = async ({
  route,
  app: { $nuxtCookies },
  redirect,
  store,
  query,
  $config
}) => {
  const callback = getAuthorizedRouteCallback(route);
  const { sessionId, publicId } = getSessionCookies($nuxtCookies);
  if (!sessionId || !publicId) return redirect(callback);

  const isAuthorize = await store.dispatch('sso/authorize', {
    publicId,
    sessionId
  });

  if (isAuthorize) {
    return store.commit('sso/SET_REDIRECTS_URIS', {
      cancelURI: getCancelLoginURI(query, $config.SSO_DEFAULT_REDIRECT)
    });
  }

  removeSessionCookies($nuxtCookies);
  redirect(callback);
};

export default loggedMiddleware;
