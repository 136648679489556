const customEvent = (
  event_category = '',
  event_action = '',
  event_label = '',
  event_noninteraction = 0,
  event_value = 0,
  value = 0.0
) => {
  try {
    window.$nuxt.$gtm.push({
      event: 'custom_event',
      event_category,
      event_action,
      event_label,
      event_noninteraction,
      event_value,
      value
    });
  } catch (e) {
    console.error(`Error sending data to GA: ${e}`);
  }
};

module.exports = {
  customEvent
};
