import { SSOState } from './state';

import { GetterTree } from 'vuex';

import {
  IAuthenticationProvider,
  AUTHENTICATION_PROVIDERS
} from '@/lib/constants';

const getAuthenticator = (
  authenticators: IAuthenticationProvider[],
  provider: string
) => {
  return authenticators.find(({ name }) => name === provider);
};

const getters: GetterTree<SSOState, SSOState> = {
  hasPinId: ({ pin }) => Boolean(pin.id),
  hasOneTapError: ({ social }) => social.oneTapError,
  googleAuthenticator: ({ authenticators }) =>
    getAuthenticator(authenticators, AUTHENTICATION_PROVIDERS.GOOGLE),
  microsoftAuthenticator: ({ authenticators }) =>
    getAuthenticator(authenticators, AUTHENTICATION_PROVIDERS.MICROSOFT),
  appleAuthenticator: ({ authenticators }) =>
    getAuthenticator(authenticators, AUTHENTICATION_PROVIDERS.APPLE),
  facebookAuthenticator: ({ authenticators }) =>
    getAuthenticator(authenticators, AUTHENTICATION_PROVIDERS.FACEBOOK),
  passwordlessAuthenticator: ({ authenticators }) =>
    getAuthenticator(authenticators, AUTHENTICATION_PROVIDERS.PASSWORDLESS),
  socialAuthenticators: (_, getters) => {
    return [
      ...(getters.googleAuthenticator ? [getters.googleAuthenticator] : []),
      ...(getters.appleAuthenticator ? [getters.appleAuthenticator] : []),
      ...(getters.facebookAuthenticator ? [getters.facebookAuthenticator] : []),
      ...(getters.microsoftAuthenticator
        ? [getters.microsoftAuthenticator]
        : [])
    ];
  }
};

export default getters;
