const { getCookie, setCookie } = require('@petlove/webstore-libs/cookies');

const { distinctArray } = require('@petlove/frontend-utilities-utils');

exports.state = {
  businessType: 'petlove',
  showDiscountBanner: false,
  features: {
    subscription: true,
    petProfile: true,
    categoryGalleries: true,
    breedsGallery: true,
    tips: true,
    newsletter: true,
    whatsappContact: false
  },
  banners: {},
  brands: [],
  breeds: {},
  contents: [],
  menus: [],
  newMenu: [],
  sellingPoints: [],
  dogHeroServices: [],
  webView: false,
  loadingSingleSignOn: false,
  showBlackHeader: false
};

exports.getters = {
  getBusinessType(state) {
    return state.businessType;
  },

  mustShowDiscountBanner(state) {
    return state.showDiscountBanner;
  },

  showSubscription(state) {
    return state.features.subscription;
  },

  showPetProfile(state) {
    return state.features.petProfile;
  },

  showCategoryGalleries(state) {
    return state.features.categoryGalleries;
  },

  showBreedsGallery(state) {
    return state.features.breedsGallery;
  },

  showTips(state) {
    return state.features.tips;
  },

  showNewsletter(state) {
    return state.features.newsletter;
  },

  showWhatsappButton(state) {
    return state.features.whatsappContact;
  },

  getBanners(state) {
    return state.banners;
  },

  getSideBanner(state) {
    return state.banners.side;
  },

  getBrands(state) {
    return state.brands;
  },

  getBreeds(state) {
    return state.breeds;
  },

  getContents(state) {
    return state.contents;
  },

  getSellingPoints(state) {
    return state.sellingPoints;
  },

  getMobileMenus(state) {
    const clearDepartments = distinctArray(state.menus, 'name');
    const dogs = clearDepartments.find(item => item.name === 'Cachorro');
    const cats = clearDepartments.find(item => item.name === 'Gatos');
    const brands = clearDepartments.find(item => item.name === 'Marcas');
    const breeds = clearDepartments.find(item => item.name === 'Raças');

    return { dogs, cats, brands, breeds };
  },

  getDesktopMenus(state) {
    const clearDepartments = distinctArray(state.menus, 'name');

    const departments = ['Cachorro', 'Gatos', 'Clube de Descontos', 'Marcas'];

    const another_pets = clearDepartments.filter(item => !departments.includes(item.name));

    const main_pets = clearDepartments.filter(item => departments.includes(item.name));

    const menus = main_pets;
    menus.splice(3, 0, {
      name: 'Outros pets',
      children: another_pets,
      desktop_banner: {
        image: 'https://www.petlove.com.br/static/uploads/images/menu/outros.jpg',
        bg_color: '#EFEFEF'
      }
    });

    return menus;
  },

  getMenu(state) {
    return state.menus;
  },

  getNewMenu(state) {
    return state.newMenu;
  },

  getBannerBoxes(state) {
    return {
      dog: state.banners?.boxes?.cachorro,
      cat: state.banners?.boxes?.gatos
    };
  },

  getWebView(state) {
    return state.webView;
  },

  getFeatures(state) {
    return state.features;
  },

  getDogHeroServices(state) {
    return state.dogHeroServices;
  },

  getLoadingSingleSignOn(state) {
    return state.loadingSingleSignOn;
  },
  getShowBlackHeader(state) {
    return state.showBlackHeader;
  }
};

exports.mutations = {
  showDiscountBanner(state) {
    state.showDiscountBanner = true;
  },

  setBanners(state, banners) {
    state.banners = banners;
  },

  setBrands(state, brands) {
    state.brands = brands;
  },

  setBreeds(state, breeds) {
    state.breeds = breeds;
  },

  setContents(state, contents) {
    state.contents = contents;
  },

  setSellingPoints(state, sellingPoints) {
    state.sellingPoints = sellingPoints;
  },

  setMenus(state, menus) {
    state.menus = menus;
  },

  setNewMenu(state, newMenu) {
    state.newMenu = newMenu;
  },

  setWebView(state, webView) {
    state.webView = webView;
  },

  setDogHeroServices(state, dogHeroServices) {
    state.dogHeroServices = dogHeroServices;
  },

  setLoadingSingleSignOn(state, loading) {
    state.loadingSingleSignOn = loading;
  }
};

exports.actions = {
  showDiscountBanner({ commit, rootGetters }) {
    const discountApplied = rootGetters['cart/getWelcomeDiscount'];

    !discountApplied && commit('showDiscountBanner');
  },
  setFirstViewCookie() {
    const firstViewCookie = getCookie('PL_firstView');
    // Do nothing when is not first view
    if (firstViewCookie === 'false') return;
    // Set first view when cookie doesnt exist
    if (!firstViewCookie) {
      setCookie('PL_firstView', 'true');
      return;
    }
    // Or set to false
    setCookie('PL_firstView', 'false');
  }
};
