exports.state = {
  campaigns: [],
  variations: []
};

exports.mutations = {
  setAbTest(state, { campaign, variation }) {
    state.campaigns.push(campaign);
    state.variations.push(variation);
  }
};

exports.getters = {
  isVariationRunning: state => campaigns => {
    return campaigns.some(({ id, variations }) => {
      const isCampaignRunning = state.campaigns.includes(id);
      if (!isCampaignRunning) return false;

      return state.variations.some(variationRunning => variations.includes(variationRunning));
    });
  },
  getVariation: state => campaigns => {
    const findVariation = campaign => {
      const variationId = campaign.variations.find(variation =>
        state.variations.includes(variation)
      );
      const variationIndex = campaign.variations.findIndex(variation =>
        state.variations.includes(variation)
      );
      return { variationId, variationIndex };
    };

    const campaign = campaigns.find(({ id }) => state.campaigns.includes(id));
    return campaign ? findVariation(campaign) : null;
  },
  campaigns: ({ campaigns }) => campaigns
};
