/**
 * @typedef {Object} IgnoredError
 * @property {string} errorName - The name of the error to be ignored.
 * @property {string} messageIncludes - A substring that should be included in the error message.
 */

/**
 * A list of errors to be ignored by Honeybadger.
 * @type {IgnoredError[]}
 */
export const IGNORED_ERRORS = [
  {
    errorName: 'ChunkLoadError',
    messageIncludes: 'Loading chunk'
  },
];
