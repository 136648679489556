import { load } from '@fingerprintjs/fingerprintjs';
import { getCookie, setCookie, COOKIE_FINGERPRINT } from '@petlove/webstore-libs/cookies';

const loadFingerprint = async () => {
  try {
    const fp = await load();
    const { visitorId } = await fp.get();
    return visitorId;
  } catch (e) {
    console.error(e);
    return '';
  }
};

export const generateFingerprint = async (fromCookie = true) => {
  if (!fromCookie) return await loadFingerprint();

  if (!getCookie(COOKIE_FINGERPRINT)) {
    const fingerprint = await loadFingerprint();
    setCookie(COOKIE_FINGERPRINT, fingerprint);
  }
};
