const middleware = {}

middleware['auth'] = require('../middleware/auth.ts')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['logged'] = require('../middleware/logged.ts')
middleware['logged'] = middleware['logged'].default || middleware['logged']

middleware['migration'] = require('../middleware/migration.ts')
middleware['migration'] = middleware['migration'].default || middleware['migration']

middleware['social'] = require('../middleware/social.ts')
middleware['social'] = middleware['social'].default || middleware['social']

export default middleware
