export const CaActionChipGroup = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/ActionChipGroup/index.vue' /* webpackChunkName: "components/ca-action-chip-group" */).then(c => wrapFunctional(c.default || c))
export const CaAvatar = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/Avatar/index.vue' /* webpackChunkName: "components/ca-avatar" */).then(c => wrapFunctional(c.default || c))
export const CaAlert = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/Alert/index.vue' /* webpackChunkName: "components/ca-alert" */).then(c => wrapFunctional(c.default || c))
export const CaAvatarGroup = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/AvatarGroup/index.vue' /* webpackChunkName: "components/ca-avatar-group" */).then(c => wrapFunctional(c.default || c))
export const CaActionChip = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/ActionChip/index.vue' /* webpackChunkName: "components/ca-action-chip" */).then(c => wrapFunctional(c.default || c))
export const CaBottomNav = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/BottomNav/index.vue' /* webpackChunkName: "components/ca-bottom-nav" */).then(c => wrapFunctional(c.default || c))
export const CaBadge = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/Badge/index.vue' /* webpackChunkName: "components/ca-badge" */).then(c => wrapFunctional(c.default || c))
export const CaButton = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/Button/index.vue' /* webpackChunkName: "components/ca-button" */).then(c => wrapFunctional(c.default || c))
export const CaCard = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/Card/index.vue' /* webpackChunkName: "components/ca-card" */).then(c => wrapFunctional(c.default || c))
export const CaCardAccordion = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/CardAccordion/index.vue' /* webpackChunkName: "components/ca-card-accordion" */).then(c => wrapFunctional(c.default || c))
export const CaCardImage = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/CardImage/index.vue' /* webpackChunkName: "components/ca-card-image" */).then(c => wrapFunctional(c.default || c))
export const CaCardSelector = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/CardSelector/index.vue' /* webpackChunkName: "components/ca-card-selector" */).then(c => wrapFunctional(c.default || c))
export const CaChat = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/Chat/index.vue' /* webpackChunkName: "components/ca-chat" */).then(c => wrapFunctional(c.default || c))
export const CaCheckbox = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/Checkbox/index.vue' /* webpackChunkName: "components/ca-checkbox" */).then(c => wrapFunctional(c.default || c))
export const CaChip = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/Chip/index.vue' /* webpackChunkName: "components/ca-chip" */).then(c => wrapFunctional(c.default || c))
export const CaChipGroup = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/ChipGroup/index.vue' /* webpackChunkName: "components/ca-chip-group" */).then(c => wrapFunctional(c.default || c))
export const CaCircularProgress = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/CircularProgress/index.vue' /* webpackChunkName: "components/ca-circular-progress" */).then(c => wrapFunctional(c.default || c))
export const CaCircularProgressBar = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/CircularProgressBar/index.vue' /* webpackChunkName: "components/ca-circular-progress-bar" */).then(c => wrapFunctional(c.default || c))
export const CaCounter = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/Counter/index.vue' /* webpackChunkName: "components/ca-counter" */).then(c => wrapFunctional(c.default || c))
export const CaCustomSwitch = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/CustomSwitch/index.vue' /* webpackChunkName: "components/ca-custom-switch" */).then(c => wrapFunctional(c.default || c))
export const CaDialog = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/Dialog/index.vue' /* webpackChunkName: "components/ca-dialog" */).then(c => wrapFunctional(c.default || c))
export const CaDivisor = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/Divisor/index.vue' /* webpackChunkName: "components/ca-divisor" */).then(c => wrapFunctional(c.default || c))
export const CaDrawer = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/Drawer/index.vue' /* webpackChunkName: "components/ca-drawer" */).then(c => wrapFunctional(c.default || c))
export const CaEmptyState = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/EmptyState/index.vue' /* webpackChunkName: "components/ca-empty-state" */).then(c => wrapFunctional(c.default || c))
export const CaFaq = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/Faq/index.vue' /* webpackChunkName: "components/ca-faq" */).then(c => wrapFunctional(c.default || c))
export const CaFooterDesktop = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/FooterDesktop/index.vue' /* webpackChunkName: "components/ca-footer-desktop" */).then(c => wrapFunctional(c.default || c))
export const CaFooterMobile = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/FooterMobile/index.vue' /* webpackChunkName: "components/ca-footer-mobile" */).then(c => wrapFunctional(c.default || c))
export const CaHeader = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/Header/index.vue' /* webpackChunkName: "components/ca-header" */).then(c => wrapFunctional(c.default || c))
export const CaIcon = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/Icon/index.vue' /* webpackChunkName: "components/ca-icon" */).then(c => wrapFunctional(c.default || c))
export const CaIconButton = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/IconButton/index.vue' /* webpackChunkName: "components/ca-icon-button" */).then(c => wrapFunctional(c.default || c))
export const CaImageCarousel = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/ImageCarousel/index.vue' /* webpackChunkName: "components/ca-image-carousel" */).then(c => wrapFunctional(c.default || c))
export const CaInputPassword = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/InputPassword/index.vue' /* webpackChunkName: "components/ca-input-password" */).then(c => wrapFunctional(c.default || c))
export const CaInputSearch = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/InputSearch/index.vue' /* webpackChunkName: "components/ca-input-search" */).then(c => wrapFunctional(c.default || c))
export const CaInputSearchAutocomplete = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/InputSearchAutocomplete/index.vue' /* webpackChunkName: "components/ca-input-search-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const CaInputSearchTag = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/InputSearchTag/index.vue' /* webpackChunkName: "components/ca-input-search-tag" */).then(c => wrapFunctional(c.default || c))
export const CaLazyImage = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/LazyImage/index.vue' /* webpackChunkName: "components/ca-lazy-image" */).then(c => wrapFunctional(c.default || c))
export const CaInputText = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/InputText/index.vue' /* webpackChunkName: "components/ca-input-text" */).then(c => wrapFunctional(c.default || c))
export const CaLinearProgressBar = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/LinearProgressBar/index.vue' /* webpackChunkName: "components/ca-linear-progress-bar" */).then(c => wrapFunctional(c.default || c))
export const CaLink = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/Link/index.vue' /* webpackChunkName: "components/ca-link" */).then(c => wrapFunctional(c.default || c))
export const CaLoading = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/Loading/index.vue' /* webpackChunkName: "components/ca-loading" */).then(c => wrapFunctional(c.default || c))
export const CaModal = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/Modal/index.vue' /* webpackChunkName: "components/ca-modal" */).then(c => wrapFunctional(c.default || c))
export const CaProductCard = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/ProductCard/index.vue' /* webpackChunkName: "components/ca-product-card" */).then(c => wrapFunctional(c.default || c))
export const CaProgressBar = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/ProgressBar/index.vue' /* webpackChunkName: "components/ca-progress-bar" */).then(c => wrapFunctional(c.default || c))
export const CaQuickFilters = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/QuickFilters/index.vue' /* webpackChunkName: "components/ca-quick-filters" */).then(c => wrapFunctional(c.default || c))
export const CaRadio = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/Radio/index.vue' /* webpackChunkName: "components/ca-radio" */).then(c => wrapFunctional(c.default || c))
export const CaRating = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/Rating/index.vue' /* webpackChunkName: "components/ca-rating" */).then(c => wrapFunctional(c.default || c))
export const CaRatingHighlightMini = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/RatingHighlightMini/index.vue' /* webpackChunkName: "components/ca-rating-highlight-mini" */).then(c => wrapFunctional(c.default || c))
export const CaSelect = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/Select/index.vue' /* webpackChunkName: "components/ca-select" */).then(c => wrapFunctional(c.default || c))
export const CaSnackbar = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/Snackbar/index.vue' /* webpackChunkName: "components/ca-snackbar" */).then(c => wrapFunctional(c.default || c))
export const CaScroll = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/Scroll/index.vue' /* webpackChunkName: "components/ca-scroll" */).then(c => wrapFunctional(c.default || c))
export const CaRatingHightlight = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/RatingHightlight/index.vue' /* webpackChunkName: "components/ca-rating-hightlight" */).then(c => wrapFunctional(c.default || c))
export const CaSlider = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/Slider/index.vue' /* webpackChunkName: "components/ca-slider" */).then(c => wrapFunctional(c.default || c))
export const CaSteps = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/Steps/index.vue' /* webpackChunkName: "components/ca-steps" */).then(c => wrapFunctional(c.default || c))
export const CaSupportContent = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/SupportContent/index.vue' /* webpackChunkName: "components/ca-support-content" */).then(c => wrapFunctional(c.default || c))
export const CaTabs = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/Tabs/index.vue' /* webpackChunkName: "components/ca-tabs" */).then(c => wrapFunctional(c.default || c))
export const CaTag = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/Tag/index.vue' /* webpackChunkName: "components/ca-tag" */).then(c => wrapFunctional(c.default || c))
export const CaTextarea = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/Textarea/index.vue' /* webpackChunkName: "components/ca-textarea" */).then(c => wrapFunctional(c.default || c))
export const CaThumbnail = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/Thumbnail/index.vue' /* webpackChunkName: "components/ca-thumbnail" */).then(c => wrapFunctional(c.default || c))
export const CaThumbnailGroup = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/ThumbnailGroup/index.vue' /* webpackChunkName: "components/ca-thumbnail-group" */).then(c => wrapFunctional(c.default || c))
export const CaTitle = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/Title/index.vue' /* webpackChunkName: "components/ca-title" */).then(c => wrapFunctional(c.default || c))
export const CaTooltip = () => import('../../../../node_modules/@petlove/caramelo-vue-components/src/Tooltip/index.vue' /* webpackChunkName: "components/ca-tooltip" */).then(c => wrapFunctional(c.default || c))
export const AuthenticationProviderApple = () => import('../../components/AuthenticationProviderApple.vue' /* webpackChunkName: "components/authentication-provider-apple" */).then(c => wrapFunctional(c.default || c))
export const AuthenticationProviderFacebook = () => import('../../components/AuthenticationProviderFacebook.vue' /* webpackChunkName: "components/authentication-provider-facebook" */).then(c => wrapFunctional(c.default || c))
export const AuthenticationProviderGoogle = () => import('../../components/AuthenticationProviderGoogle.vue' /* webpackChunkName: "components/authentication-provider-google" */).then(c => wrapFunctional(c.default || c))
export const AuthenticationProviderMicrosoft = () => import('../../components/AuthenticationProviderMicrosoft.vue' /* webpackChunkName: "components/authentication-provider-microsoft" */).then(c => wrapFunctional(c.default || c))
export const CardOthersSessions = () => import('../../components/CardOthersSessions.vue' /* webpackChunkName: "components/card-others-sessions" */).then(c => wrapFunctional(c.default || c))
export const CardPrivacy = () => import('../../components/CardPrivacy.vue' /* webpackChunkName: "components/card-privacy" */).then(c => wrapFunctional(c.default || c))
export const EmailLogin = () => import('../../components/EmailLogin.vue' /* webpackChunkName: "components/email-login" */).then(c => wrapFunctional(c.default || c))
export const InputAutocomplete = () => import('../../components/InputAutocomplete.vue' /* webpackChunkName: "components/input-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const PinLogin = () => import('../../components/PinLogin.vue' /* webpackChunkName: "components/pin-login" */).then(c => wrapFunctional(c.default || c))
export const SessionInformation = () => import('../../components/SessionInformation.vue' /* webpackChunkName: "components/session-information" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeaderSSO = () => import('../../components/layout/HeaderSSO.vue' /* webpackChunkName: "components/layout-header-s-s-o" */).then(c => wrapFunctional(c.default || c))
export const LayoutUserInformation = () => import('../../components/layout/UserInformation.vue' /* webpackChunkName: "components/layout-user-information" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
