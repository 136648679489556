import Vue from 'vue';
import HoneybadgerVue from '@honeybadger-io/vue';
import { IGNORED_ERRORS } from '../utils/honeybadger-constants';
import { isBot } from '@petlove/webstore-libs/isBot';

Vue.use(HoneybadgerVue, {});

export const honeybadger = ({ $config, store }, inject) => {
  const config = {
    apiKey: $config?.honeybadger?.key,
    environment: $config?.honeybadger?.env || 'development',
    developmentEnvironments: ['dev', 'development', 'test', 'staging'],
    revision: 'main'
  };

  Vue.$honeybadger?.configure(config);

  const { public_id, email } = store.getters?.['auth/getSession'] ?? {};

  Vue.$honeybadger?.setContext({
    user_email: email ?? '',
    user_id: public_id ?? '',
    subscriber: store.getters?.['users/subscriber'],
    isClubMember: store.getters?.['users/isClubMember'],
    zipcode: store.getters?.['shipment/selectedZipcode']
  });

  Vue.$honeybadger.beforeNotify(notice => {
    const userAgent = navigator?.userAgent ?? '';
    if (userAgent && isBot(userAgent)) {
      return false;
    }

    const shouldIgnore = IGNORED_ERRORS.some(({ errorName, messageIncludes }) => {
      const nameMatches = errorName && notice.name === errorName;
      const messageMatches = messageIncludes && notice.message?.includes(messageIncludes);
      return nameMatches || messageMatches;
    });

    return !shouldIgnore;
  });

  inject('honeybadger', Vue.$honeybadger);
};
