// @ts-ignore
import { state as _state } from '@petlove/webstore-store/ui';

interface IUiState {
  webView: boolean;
}

const state = (): IUiState => ({ ..._state });

export default state;
export type UiState = ReturnType<typeof state>;
