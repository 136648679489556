exports.state = {
  store: null,
  webStore: null
};

exports.mutations = {
  setStore(state, store) {
    state.store = store;
  },
  setWebStore(state, store) {
    state.webStore = store;
  }
};

exports.getters = {
  getStore(state) {
    return state.store;
  },

  getWebStore(state) {
    return state.webStore;
  },

  getProducts(state) {
    return state.store ? state.store.products : [];
  },

  getTheme(state) {
    return state.store ? state.store.color : null;
  },

  getWhatsapp(state) {
    return state.store && state.store.contacts?.find(contact => contact.kind === 'whatsapp')?.value;
  },

  isVet(state) {
    return state.store && state.store?.segment?.vet;
  }
};
