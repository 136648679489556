import {
  IDobermanSession,
  IAuthenticationProvider,
  AUTHENTICATION_PROVIDERS,
  PUBLIC_ID_COOKIE,
  REFERENCE_ID_COOKIE,
  HAS_LOGGED_SESSION,
  PETLOVE_COOKIE_DOMAIN,
  HIDE_SOCIAL
} from './constants';

import { Context, NuxtAppOptions } from '@nuxt/types';
// @ts-ignore
import { decode } from '@petlove/frontend-utilities-utils';
// @ts-ignore
import { customEvent } from '@petlove/webstore-libs/analytics/events/customEvents';
import { NuxtCookies } from 'cookie-universal-nuxt';
import Vue from 'vue';
import { Route } from 'vue-router';

export const getSessionCookies = ($nuxtCookies: NuxtCookies) => {
  const publicId: string | undefined = $nuxtCookies.get(PUBLIC_ID_COOKIE);
  const sessionId: string | undefined = $nuxtCookies.get(REFERENCE_ID_COOKIE);
  return { sessionId, publicId };
};

export const removeSessionCookies = ($nuxtCookies: NuxtCookies) => {
  $nuxtCookies.remove(PUBLIC_ID_COOKIE);
  $nuxtCookies.remove(REFERENCE_ID_COOKIE);
  $nuxtCookies.remove(HAS_LOGGED_SESSION, { domain: PETLOVE_COOKIE_DOMAIN });
};

export const generateRedirectURI = (redirectURI: string, token: string) => {
  let url: URL;

  try {
    url = new URL(redirectURI);
  } catch (error: unknown) {
    url = new URL(process.env.NUXT_ENV_ECOM as string);
  }

  url.searchParams.append('et', token);
  return url.toString();
};

export const getRedirectURI = ({ vcb }: { vcb?: string }) => {
  return decode(vcb ?? '') || vcb || process.env.NUXT_ENV_ECOM;
};

export const getCancelLoginURI = (
  { ccb }: { ccb?: string },
  redirectURI: string
) => {
  const cancelURI = decode((ccb as string) ?? '') || ccb;
  if (cancelURI) return cancelURI;

  let url: URL;
  try {
    url = new URL(redirectURI);
  } catch (error: unknown) {
    url = new URL(process.env.NUXT_ENV_ECOM as string);
  }

  return url.origin;
};

export const redirectToURL = (
  redirectURI: string,
  exchange_token: string,
  redirect?: Function
) => {
  const url = generateRedirectURI(redirectURI, exchange_token);

  if (redirect) redirect(url);
  else if (window) window.location.replace(url);
};

export const saveSession = (
  { session }: { session: IDobermanSession },
  $nuxtCookies: NuxtCookies
) => {
  const {
    reference_id,
    user: { public_id }
  } = session ?? { reference_id: '', user: { public_id: '' } };

  $nuxtCookies.set(PUBLIC_ID_COOKIE, public_id, { maxAge: 120 * 24 * 60 * 60 });
  $nuxtCookies.set(REFERENCE_ID_COOKIE, reference_id, {
    maxAge: 120 * 24 * 60 * 60
  });

  $nuxtCookies.set(HAS_LOGGED_SESSION, true, {
    maxAge: 120 * 24 * 60 * 60,
    domain: PETLOVE_COOKIE_DOMAIN
  });
};

export const validSessionCreated = ({
  redirectURI,
  session,
  $nuxtCookies,
  $route,
  $router,
  redirect
}: {
  redirectURI: string;
  session: { session: IDobermanSession; exchange_token: string };
  $nuxtCookies: NuxtCookies;
  $route?: Route;
  $router?: { push: Function };
  redirect?: Function;
}) => {
  saveSession(session, $nuxtCookies);
  const routeCallback = decode(($route?.query.icb as string) ?? '');
  if (routeCallback) return $router?.push(routeCallback);
  return redirectToURL(redirectURI as string, session.exchange_token, redirect);
};

export const generateLoggedToken = async ({
  $axios,
  $nuxtCookies
}: NuxtAppOptions) => {
  const { sessionId: id, publicId } = getSessionCookies($nuxtCookies);
  if (!id || !publicId) return null;

  const { exchange_token } = await $axios.$post('/sso/exchange_token', {
    id,
    publicId
  });

  if (!exchange_token) {
    removeSessionCookies($nuxtCookies);
  }

  return exchange_token;
};

export const destroySession = async ({
  app,
  query,
  store,
  redirect
}: Context) => {
  let redirectURI;

  try {
    const dcb = decode((query.dcb as string) ?? '') || query.dcb;
    redirectURI = new URL(dcb as string).toString();
  } catch (error) {
    redirectURI = '/';
  }

  const { sessionId } = getSessionCookies(app.$nuxtCookies);
  await store.dispatch('sso/delete', [sessionId]);
  return redirect(redirectURI);
};

export const authenticateWithSocialProvider = async ({
  provider,
  component,
  credential
}: {
  component: Vue;
  credential: string;
  provider: IAuthenticationProvider;
}) => {
  const { $store } = component;
  const response = await $store.dispatch('sso/social', {
    provider,
    credential
  });

  const method = provider.name.toLowerCase();
  if (!response.error && Boolean(response.exchange_token)) {
    customEvent('login', `${method}:sucesso`, 'login');
    const { $nuxtCookies, $route, $router } = component;

    return validSessionCreated({
      $route,
      $router,
      $nuxtCookies,
      session: response,
      redirectURI: $store.state.sso.redirectURI
    });
  }

  customEvent('login', `${method}:falha`, 'login');
  return response;
};

export const shouldHideSocialOptions = (hideSocial: string) => {
  return hideSocial === HIDE_SOCIAL.ALL;
};

export const filterToHideSocialOptions = (
  authentication_providers: IAuthenticationProvider[]
): IAuthenticationProvider[] => {
  return authentication_providers.filter(
    (provider: IAuthenticationProvider) =>
      provider.name === AUTHENTICATION_PROVIDERS.PASSWORDLESS
  );
};
