import { Middleware } from '@nuxt/types';

import { getRedirectURI, validSessionCreated } from '@/lib/login';

const socialLoginMiddleware: Middleware = async ({
  app,
  query,
  store,
  redirect
}) => {
  const googleToken = query.gt as string;
  const petloveFingerprint = query.plf as string;
  const googleAuthenticatorProvider = store.getters['sso/googleAuthenticator'];

  if (!googleToken || !petloveFingerprint || !googleAuthenticatorProvider) {
    return;
  }

  const redirectURI: string = getRedirectURI(query) ?? '';
  store.commit('sso/SET_FINGERPRINT', petloveFingerprint);
  store.commit('sso/SET_REDIRECTS_URIS', { redirectURI });

  const response = await store.dispatch('sso/social', {
    credential: googleToken,
    provider: googleAuthenticatorProvider
  });

  if (!response.error && Boolean(response.exchange_token)) {
    return validSessionCreated({
      redirect,
      redirectURI,
      session: response,
      $nuxtCookies: app.$nuxtCookies
    });
  } else if (response.error) {
    store.commit('sso/SET_ONE_TAP_ERROR', true);
  }
};

export default socialLoginMiddleware;
