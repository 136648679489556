import { Context } from '@nuxt/types';
// @ts-ignore
import { honeybadger } from '@petlove/webstore-plugins/honeybadger';
import Vue from 'vue';

export default (context: Context, inject: Function) => {
  honeybadger(context, inject);

  // @ts-ignore
  Vue.$honeybadger?.beforeNotify((notice: { message: string }) => {
    if (/Object Not Found Matching Id:/.test(notice.message)) {
      return false;
    }
  });
};
