/* eslint-disable no-unused-vars */
import { Method } from 'axios';
export interface IRequestData {
  url: string;
  method: Method;
  data?: { [key: string]: any };
  headers?: { [key: string]: string };
  params?: { [key: string]: string | number };
}

export interface IResponseError {
  message: string | { email: string[] };
}

export interface IResponseFacebook {
  authResponse: { accessToken: string };
}

export interface IResponseGoogle {
  credential: string;
}

export interface IDobermanSession {
  reference_id: string;
  user: { public_id: string };
}

export interface IAuthenticationProvider {
  api_path: string;
  name: string;
  order_number: number;
}
export interface ISession {
  current: boolean;
  inserted_at: string;
  interacted_at: string;
  device: { name: string; app: string };
  reference_id: string;
}

export const PUBLIC_ID_COOKIE = 'PL_sso_id';
export const REFERENCE_ID_COOKIE = '_sid';
export const HAS_LOGGED_SESSION = 'PL_sso_logged';
export const PETLOVE_COOKIE_DOMAIN = 'petlove.com.br';
export const PIN_CODE_NUMBERS = 6;

export const MINUTES_PER_HOUR = 60;
export const SECONDS_PER_MINUTE = 60;
export const MILLISECONDS_PER_SECOND = 1000;

export enum EMAILS_PROVIDERS {
  GMAIL = '@gmail.com',
  HOTMAIL = '@hotmail.com',
  YAHOO = '@yahoo.com.br',
  OUTLOOK = '@outlook.com',
  UOL = '@uol.com.br',
  ICLOUD = '@icloud.com',
  BOL = '@bol.com.br',
  LIVE = '@live.com',
  YAHOO_GLOBAL = '@yahoo.com',
  TERRA = '@terra.com.br'
}

export enum AUTHENTICATION_PROVIDERS {
  APPLE = 'Apple',
  GOOGLE = 'Google',
  FACEBOOK = 'Facebook',
  MICROSOFT = 'Microsoft',
  PASSWORDLESS = 'Passwordless'
}

export enum AUTHENTICATION_PROVIDERS_KEYS {
  APPLE = 'code',
  GOOGLE = 'id_token',
  FACEBOOK = 'access_token',
  MICROSOFT = 'access_token'
}

export enum SESSIONS_LOAD_DIRECTION {
  TOP = 'top',
  BOTTOM = 'bottom'
}

export enum HIDE_SOCIAL {
  ALL = 'ALL'
}
