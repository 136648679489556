import { SSOState } from './state';

import { ActionTree, Commit } from 'vuex';

import {
  getSessionCookies,
  removeSessionCookies,
  shouldHideSocialOptions,
  filterToHideSocialOptions
} from '@/lib/login';

const savePin = async (response: Response, commit: Commit, email = '') => {
  const pin = await response.json();
  if (pin.error) return { error: pin.error };

  commit('SET_PIN_REFERENCE', { pin: pin.pin_code, email });
};

const getBaseUrl = () => {
  if (typeof window === 'undefined') return '';
  return window.location.origin;
};

const actions: ActionTree<SSOState, SSOState> = {
  async passwordless({ commit, state }, { email }) {
    const { redirectURI, fingerprint } = state;

    const response = await fetch('/sso/passwordless', {
      method: 'POST',
      body: JSON.stringify({ email, fingerprint, redirectURI })
    });

    return await savePin(response, commit, email);
  },
  async validate({ state }, { code }) {
    const {
      pin: { id },
      redirectURI
    } = state;

    const response = await fetch('/sso/validate', {
      method: 'POST',
      body: JSON.stringify({ id, code, redirectURI })
    });

    return await response.json();
  },
  async resend({ commit, state }) {
    const {
      pin: { id }
    } = state;

    const response = await fetch(`/sso/resend/${id}`);
    return await savePin(response, commit);
  },
  async social({ state }, { credential, provider }) {
    const { redirectURI, fingerprint } = state;
    const { name, api_path } = provider;

    return await this.$axios.$post(`${getBaseUrl()}/sso/social`, {
      name,
      api_path,
      credential,
      fingerprint,
      redirectURI
    });
  },
  async migrate(
    _,
    { redirectURI, rottweilerSessionId, rottweilerFingerprint }
  ) {
    const response = await this.$axios.$post('/sso/migrate', {
      redirectURI,
      rottweilerSessionId,
      rottweilerFingerprint
    });

    return response;
  },
  async authorize({ commit }, { publicId, sessionId }) {
    const response = await this.$axios.$post(`${getBaseUrl()}/sso/authorize`, {
      publicId,
      sessionId
    });

    if (response.error) return false;

    const {
      session: {
        user: { email }
      }
    } = response;

    commit('SET_EMAIL', email);
    return true;
  },
  async available(_, page) {
    const { sessionId, publicId } = getSessionCookies(this.$nuxtCookies);

    return await this.$axios.$get(`${getBaseUrl()}/sso/available`, {
      params: {
        page,
        publicId,
        sessionId
      }
    });
  },
  async delete(_, ids) {
    const { sessionId: id, publicId } = getSessionCookies(this.$nuxtCookies);
    if (!id || !publicId) return;

    await this.$axios.$delete(`${getBaseUrl()}/sso/destroy`, {
      data: { publicId, id, ids }
    });

    if (ids.includes(id)) {
      removeSessionCookies(this.$nuxtCookies);
    }
  },
  async providers({ commit }, context) {
    const hideSocial = context.route.query.hideSocial || '';

    const { authentication_providers = [] } = await this.$axios.$get(
      `${getBaseUrl()}/sso/providers`
    );

    let filteredProviders = authentication_providers;

    if (shouldHideSocialOptions(hideSocial)) {
      filteredProviders = filterToHideSocialOptions(authentication_providers);
    }

    commit('SET_AUTHENTICATORS', filteredProviders);
  }
};

export default actions;
