import { IAuthenticationProvider } from '@/lib/constants';

interface IPinCode {
  id: string;
  resend: string;
  expiration: string;
  blocked: boolean;
}

interface ISnackbar {
  type: string;
  show: boolean;
  message: string;
}

interface IMigration {
  successful: boolean;
  needsValidation: boolean;
}

interface ISocial {
  oneTapError: Boolean;
}

interface ISSOState {
  authenticators: IAuthenticationProvider[];
  email: string;
  redirectURI: string;
  cancelURI: string;
  fingerprint: string;
  pin: IPinCode;
  snackbar: ISnackbar;
  migration: IMigration;
  social: ISocial;
}

const state = (): ISSOState => ({
  authenticators: [],
  email: '',
  fingerprint: '',
  cancelURI: '',
  redirectURI: '',
  pin: {
    id: '',
    resend: '',
    expiration: '',
    blocked: false
  },
  snackbar: {
    show: false,
    type: 'negative',
    message: ''
  },
  migration: {
    successful: false,
    needsValidation: false
  },
  social: {
    oneTapError: false
  }
});

export default state;
export type SSOState = ReturnType<typeof state>;
