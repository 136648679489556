// @ts-ignore
import { state as _state } from '@petlove/webstore-store/whitelabel';

interface IStore {
  logo: string;
  name: string;
  isWhitelabel: boolean;
}

interface IWhitelabelState {
  store: IStore | null;
}

const state = (): IWhitelabelState => ({ ..._state });

export default state;
export type WhitelabelState = ReturnType<typeof state>;
