import { Context } from '@nuxt/types';
import { Store } from 'vuex';

import { destroySession } from '@/lib/login';

const state = () => ({
  context: {},
  SMSPageMessage: '',
  username: '',
  origin: ''
});

const actions = {
  async nuxtServerInit(store: Store<any>, context: Context) {
    const { req, route, query, $config } = context;

    if (req.url === '/healthcheck') return;
    if (req.url?.startsWith('/destroy')) return await destroySession(context);

    const { webview = '', wlsd = '' } = query;
    store.commit('ui/setWebView', webview === 'true');

    if (route.name === 'index') await store.dispatch('sso/providers', context);

    if (wlsd) {
      await store.dispatch('whitelabel/getWhitelabelStore', {
        subdomain: wlsd,
        labrador: $config.LABRADOR_INTERNAL_URL
      });
    }
  }
};

const mutations = {};
const getters = {};

const set = (key: any) => (state: any, val: any) => {
  state[key] = val;
};
const get = (key: any) => (state: any) => {
  return state[key];
};
Object.keys(state()).forEach((key: any) => {
  // @ts-ignore
  mutations[key] = set(key);
  // @ts-ignore
  getters[key] = get(key);
});

export { actions, getters, mutations };
