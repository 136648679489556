import * as msal from '@azure/msal-browser';
import { Plugin } from '@nuxt/types';

const microsoftPlugin: Plugin = ({ $config }, inject) => {
  const config: msal.Configuration = {
    auth: {
      clientId: $config.social.microsoft
    }
  };

  const msalInstance = new msal.PublicClientApplication(config);
  inject('microsoft', msalInstance);
};

export default microsoftPlugin;
