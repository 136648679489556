import { Middleware } from '@nuxt/types';

import { HAS_LOGGED_SESSION } from '@/lib/constants';
import {
  getRedirectURI,
  getCancelLoginURI,
  generateRedirectURI,
  generateLoggedToken,
  removeSessionCookies
} from '@/lib/login';

const authMiddleware: Middleware = async ({ query, app, redirect, store }) => {
  if (store.state.sso.migration.successful) return;

  const redirectURI = getRedirectURI(query);
  const token = await generateLoggedToken(app);
  if (token) return redirect(generateRedirectURI(redirectURI as string, token));

  const loggedCookie = app.$nuxtCookies.get(HAS_LOGGED_SESSION);
  const cancelURI = getCancelLoginURI(query, redirectURI as string);

  if (loggedCookie) {
    removeSessionCookies(app.$nuxtCookies);
    return redirect(cancelURI);
  }

  store.commit('sso/SET_REDIRECTS_URIS', {
    cancelURI,
    redirectURI
  });
};

export default authMiddleware;
